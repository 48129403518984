import { mapActions, mapGetters } from 'vuex';
import {
  email, maxLength, minLength, required,
} from 'vuelidate/lib/validators';
import { cloneDeep } from 'lodash';
import { firstnameValidation, lastnameValidation } from '@/common/validationHelpers';
import {
  COMPANY, EMAIL, FIRST_NAME, LAST_NAME, TAXPAYER_ID_NUMBER,
} from '@/common/constants/validations';
import { MESSAGES } from '@/common/constants/errorMessages';

export default {
  validations: {
    editedItem: {
      last_name: { lastnameValidation, maxLength: maxLength(LAST_NAME.MAX_LENGTH) },
      first_name: { firstnameValidation, maxLength: maxLength(FIRST_NAME.MAX_LENGTH) },
      company: { required, maxLength: maxLength(COMPANY.MAX_LENGTH) },
      email: { required, email, maxLength: maxLength(EMAIL.MAX_LENGTH) },
      taxpayer_id_number: {
        minLength: minLength(TAXPAYER_ID_NUMBER.MIN_LENGTH),
        maxLength: maxLength(TAXPAYER_ID_NUMBER.MAX_LENGTH),
      },
    },
  },
  data: () => ({
    editedItem: {
      last_name: '',
      first_name: '',
      company: '',
      email: '',
      taxpayer_id_number: '',
    },
    defaultItem: {
      last_name: '',
      first_name: '',
      company: '',
      email: '',
      taxpayer_id_number: '',
    },
  }),
  computed: {
    ...mapGetters({
      loading: 'users/managers/loading',
      errors: 'users/managers/errors',
      editedManager: 'users/managers/editedManager',
    }),
    dialogCreateShow: {
      get() {
        return this.$store.getters['users/managers/dialogCreateShow'];
      },
      set(val) {
        this.setDialogCreateShow(val);
        if (!val) {
          this.$nextTick(() => {
            this.editedItem = cloneDeep(this.defaultItem);
          });
          this.$v.$reset();
        }
      },
    },
    dialogDeleteShow: {
      get() {
        return this.$store.getters['users/candidates/dialogDeleteShow'];
      },
      set(val) {
        this.setDialogDeleteShow(val);
      },
    },
    lastnameErrors() {
      const errors = [];
      if (!this.$v.editedItem.last_name.$dirty) return errors;
      if (!this.$v.editedItem.last_name.lastnameValidation) errors.push(MESSAGES.INCORRECT_LASTNAME);
      if (!this.$v.editedItem.last_name.maxLength) errors.push(MESSAGES.MAX_LENGTH(LAST_NAME.MAX_LENGTH));

      return this.errors.create.find((err) => err[0] === 'last_name')?.[1] ?? errors;
    },
    firstnameErrors() {
      const errors = [];
      if (!this.$v.editedItem.first_name.$dirty) return errors;
      if (!this.$v.editedItem.first_name.firstnameValidation) errors.push(MESSAGES.INCORRECT_FIRSTNAME);
      if (!this.$v.editedItem.first_name.maxLength) errors.push(MESSAGES.MAX_LENGTH(FIRST_NAME.MAX_LENGTH));

      return this.errors.create.find((err) => err[0] === 'first_name')?.[1] ?? errors;
    },
    companyErrors() {
      const errors = [];
      if (!this.$v.editedItem.company.$dirty) return errors;
      if (!this.$v.editedItem.company.required) errors.push(MESSAGES.REQUIRED);
      if (!this.$v.editedItem.company.maxLength) errors.push(MESSAGES.MAX_LENGTH(COMPANY.MAX_LENGTH));

      return this.errors.create.find((err) => err[0] === 'company')?.[1] ?? errors;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.editedItem.email.$dirty) return errors;
      if (!this.$v.editedItem.email.required) errors.push(MESSAGES.REQUIRED);
      if (!this.$v.editedItem.email.email) errors.push(MESSAGES.INCORRECT_EMAIL);
      if (!this.$v.editedItem.email.maxLength) errors.push(MESSAGES.MAX_LENGTH(EMAIL.MAX_LENGTH));

      return this.errors.create.find((err) => err[0] === 'email')?.[1] ?? errors;
    },
    taxpayerIdNumberErrors() {
      const errors = [];
      if (!this.$v.editedItem.taxpayer_id_number.$dirty) return errors;
      if (!this.$v.editedItem.taxpayer_id_number.minLength) {
        errors.push(MESSAGES.MIN_LENGTH(TAXPAYER_ID_NUMBER.MIN_LENGTH));
      }
      if (!this.$v.editedItem.taxpayer_id_number.maxLength) {
        errors.push(MESSAGES.MAX_LENGTH(TAXPAYER_ID_NUMBER.MAX_LENGTH));
      }

      return this.errors.create.find((err) => err[0] === 'taxpayer_id_number')?.[1] ?? errors;
    },
  },
  methods: {
    ...mapActions({
      clearError: 'users/managers/clearError',
      setDialogCreateShow: 'users/managers/setDialogCreateShow',
      createManager: 'users/managers/createManager',
    }),
    deleteManagerConfirm() {
      this.deleteManagers([this.editedManager.id]).then(() => {
        this.dialogDeleteShow = false;
      });
    },
    onInput() {
      if (this.errors.create.length) this.clearError();
    },
    createManagerConfirm() {
      if (this.loading.create) return;
      this.$v.$touch();
      if (!this.editedManager && !this.$v.$invalid) {
        this.createManager(this.editedItem).then(() => {
          this.dialogCreateShow = false;
        });
      }
    },
  },
};
